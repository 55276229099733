import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import RichText from "../atoms/RichText"

const Faq = ({ title, richText, rest }) => {
  if (!richText?.content) return null

  return (
    <RichText
      shadow="none"
      textAlign="left"
      mt={0}
      mr={{ _: 0, md: 9, lg: 13 }}
      mb={4}
      // ml={{ _: 0, md: 6 }}
      {...richText}
      {...rest}
    />
  )
}

export default Faq

Faq.strapiProps = {
  title: PropTypes.string,
  paragraph: PropTypes.object,
}

Faq.propTypes = {
  ...Faq.strapiProps,
}

export const query = graphql`
  fragment Faq on STRAPI__COMPONENT_MOLECULES_FAQ {
    title
    richText {
      ...RichText
    }
  }
`
