import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { getRedirectURL } from "../utils/utils"

const RedirectPage = ({ data, pageContext }) => {
  const siteURL = data.strapiSite.url
  const rawRedirectUrl = pageContext.redirect

  let redirectUrl = getRedirectURL(rawRedirectUrl, siteURL)

  return (
    <Helmet>
      <meta http-equiv="refresh" content={`0;url=${redirectUrl}`} />
    </Helmet>
  )
}

export default RedirectPage

export const query = graphql`
  query RedirectPage($siteId: Int!) {
    strapiSite(strapi_id: { eq: $siteId }) {
      url
    }
  }
`
