import React from "react"
import { graphql } from "gatsby"
import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"

import Box from "../atoms/Box"
import SectionTitle from "../molecules/SectionTitle"
import ImageTextSection from "./ImageText/ImageTextSection"
import { getSectionId } from "../page/Sections"
import { cloneDeep } from "lodash"
import FlexGrid from "../atoms/FlexGrid"

const FlexColumns = ({
  section,
  sectionTitle,
  FCSectionTitle,
  flexColumns,
  flexGrid,
  columnsRatio,
  transparentBg,
}) => {
  const sectionTitleObj = sectionTitle || FCSectionTitle

  return (
    <Section {...section}>
      <Reveal>
        <Box>
          <SectionTitle {...sectionTitleObj} />
          <FlexGrid columnsRatio={columnsRatio} {...flexGrid}>
            {flexColumns &&
              flexColumns.map(flexColumn => {
                const section = cloneDeep(flexColumn)
                if (section.section) {
                  if (section.section.hidden) {
                    return null
                  }
                  section.section.sectionId = getSectionId(section)
                }
                return (
                  <ImageTextSection
                    {...flexColumn}
                    transparentBg={transparentBg}
                  />
                )
              })}
          </FlexGrid>
        </Box>
      </Reveal>
    </Section>
  )
}

export default FlexColumns

export const query = graphql`
  fragment FlexColumns on STRAPI__COMPONENT_SECTIONS_FLEX_COLUMNS {
    strapi_id
    section {
      ...Section
    }
    FCSectionTitle: sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    flexColumns {
      ...ImageTextSection
    }
    columnsRatio
    mobileSpacing
    transparentBg
  }
`
