import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Flex from "../atoms/Flex"
import TextField from "../atoms/TextField"
import Box from "../atoms/Box"
import styled from "styled-components"
import Search from "../icons/Search"

const StyledTextField = styled(TextField)`
  .MuiInput-input {
    padding-left: 30px;
  }
`

const StyledSearchIcon = styled(Search)`
  position: absolute;
  top: 21px;
  left: 15px;
`

const SearchGrid = ({
  searchTerm,
  handleSearch,
  alignment,
  placeholder,
  themeColors,
  ...rest
}) => {
  // reassign section theme if assigned as "default"
  const sectionTheme =
    themeColors?.sectionTheme === "default"
      ? "light"
      : themeColors?.sectionTheme
  const iconColor = themeColors?.themes?.[`${sectionTheme}`]?.colors?.text

  const align =
    alignment === "left"
      ? "start"
      : alignment === "center"
      ? "center"
      : "flex-end"
  return (
    <Flex flexDirection="column" alignItems={`${align}`} {...rest}>
      <Box position="relative" mb={4} width="50%">
        <StyledSearchIcon iconColor={`${iconColor}`} />
        <StyledTextField
          value={searchTerm}
          onChange={e => handleSearch(e)}
          placeholder={placeholder}
          searchComponent={Search}
          designatedColor={iconColor}
        />
      </Box>
    </Flex>
  )
}

SearchGrid.strapiProps = {
  useSort: PropTypes.bool,
  optionOneBool: PropTypes.bool,
  optionTwoBool: PropTypes.bool,
  optionThreeBool: PropTypes.bool,
  alignSearchBar: PropTypes.oneOf(["left", "center", "right"]),
}

export default SearchGrid

export const query = graphql`
  fragment SearchGrid on STRAPI__COMPONENT_MOLECULES_SEARCH_GRID {
    useSearch
    optionOneBool
    optionTwoBool
    optionThreeBool
    alignSearchBar
    defaultSearchBarText
  }
`
