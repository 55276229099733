import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Flex from "../atoms/Flex"
import FilterTabs from "./FilterTabs"
import FilterDropdown from "./FilterDropdown"

const Sort = ({
  id,
  name,
  allLabel,
  location,
  optionsObj,
  optionOneBool,
  optionTwoBool,
  optionThreeBool,
  customOrderBool,
  customOrderTitle,
  setCurrentSort,
  alignMenuItems,
  showFilterLabel,
  layout = "dropdown",
  dropdownCard,
  ...rest
}) => {
  const [selected, setSelected] = useState({ [id]: allLabel })
  const [menuOpen, setMenuOpen] = useState(false)
  const [options, setOptions] = useState([])
  const anchorRef = useRef(null)

  const conditionalProps = {}
  switch (location) {
    case "right":
      conditionalProps.ml = 7
      break
    case "split":
      conditionalProps.ml = { _: 5, md: 7 }
      conditionalProps.mr = { _: 5, md: 0 }
      break
    case "center":
      conditionalProps.mx = 5
      break
    case "left":
      conditionalProps.mr = { _: 7, md: 5 }
      break
    case "splitReverse":
      conditionalProps.mr = { _: 5, md: 7 }
      conditionalProps.ml = { _: 5, md: 0 }
      break
    default:
      conditionalProps.mr = 7
  }

  useEffect(() => {
    setCurrentSort(selected?.[id] === allLabel ? null : selected)
  }, [selected])

  useEffect(() => {
    const boolsObj = {
      customOrderBool,
      optionOneBool,
      optionTwoBool,
      optionThreeBool,
    }
    let filteredOptions = []
    for (const [key, value] of Object.entries(optionsObj)) {
      if (boolsObj[key]) filteredOptions.push(value)
    }
    setOptions(filteredOptions)
  }, [
    optionOneBool,
    optionTwoBool,
    optionThreeBool,
    customOrderBool,
    customOrderTitle,
  ])

  return (
    <Flex
      flexDirection="column"
      justifyContent={showFilterLabel ? "flex-end" : "flex-start"}
      mb={4}
      {...conditionalProps}
    >
      {layout === "tabs" ? (
        <FilterTabs
          allLabel={allLabel}
          menuOpen={menuOpen}
          options={options}
          anchorRef={anchorRef}
          showFilterLabel={false}
          alignMenuItems={alignMenuItems}
          location={location}
          selected={selected}
          name={name}
          id={id}
          setSelected={setSelected}
          setMenuOpen={setMenuOpen}
          {...rest}
        />
      ) : (
        <FilterDropdown
          allLabel={allLabel}
          menuOpen={menuOpen}
          options={options}
          anchorRef={anchorRef}
          showFilterLabel={false}
          alignMenuItems={alignMenuItems}
          location={location}
          selected={selected}
          name={name}
          id={id}
          dropdownCard={dropdownCard}
          setSelected={setSelected}
          setMenuOpen={setMenuOpen}
          {...rest}
        />
      )}
    </Flex>
  )
}

Sort.strapiProps = {
  useSort: PropTypes.bool,
  optionOneBool: PropTypes.bool,
  optionTwoBool: PropTypes.bool,
  optionThreeBool: PropTypes.bool,
  customOrderBool: PropTypes.bool,
  customOrderTitle: PropTypes.string,
  defaultSortSelection: PropTypes.oneOf([
    "customOrderBool",
    "optionOneBool",
    "optionTwoBool",
    "optionThreeBool",
  ]),
  layout: PropTypes.oneOf(["dropdown", "tabs"]),
  alignMenuItems: PropTypes.oneOf(["left", "center", "right"]),
}

export default Sort

export const query = graphql`
  fragment Sort on STRAPI__COMPONENT_MOLECULES_SORT {
    useSort
    optionOneBool
    optionTwoBool
    optionThreeBool
    customOrderBool
    customOrderTitle
    defaultSortSelection
    layout
    alignMenuItems
    dropdownCard {
      ...Card
    }
  }
`
