import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Box from "./Box"
import Button from "./Button"

const Filter = ({
  id,
  showClearFiltersBtn,
  clearFiltersBtnText,
  alignment,
  handleClearFilters,
  ...rest
}) => {
  if (!showClearFiltersBtn) return null

  return (
    <Box
      //   mb={6}
      //   mt={!isTagFilterOn ? 4 : 0}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent={
        alignment === "center"
          ? "center"
          : alignment === "right"
          ? "flex-end"
          : "flex-start"
      }
      {...rest}
    >
      <Button variant="link" hideArrow onClick={handleClearFilters}>
        {clearFiltersBtnText}
      </Button>
    </Box>
  )
}

Filter.strapiProps = {
  name: PropTypes.string,
  allLabel: PropTypes.string.isRequired,
  showFilterLabel: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
}

export default Filter

export const query = graphql`
  fragment ClearFiltersButton on STRAPI__COMPONENT_ATOMS_CLEAR_BUTTON {
    id
    showClearFiltersBtn
    clearFiltersBtnText
    alignment
  }
`
