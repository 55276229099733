import React, { useState, useEffect, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { debounce } from "lodash"

import Reveal from "../utils/Reveal"

import Button from "../atoms/Button"
import ClearFiltersButton from "../atoms/ClearFiltersButton"
import FlexGrid from "../atoms/FlexGrid"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Filter from "../atoms/Filter"
import Sort from "../molecules/Sort"

const FilteredGrid = ({
  flexGrid,
  children,
  loadMoreBtnStyles,
  centerFilter,
  filters = [],
  filtersStyles,
  filterSortLocation,
  numToLoad = 6,
  currentColumns,
  sort,
  searchedTerm,
  searchProps,
  selectedAlphaLetterFilter,
  alphabeticalFilter,
  gridInitialView = "allItems",
  clearFiltersBtn,
  filtersResetKey,
}) => {
  const filterPriority = searchProps?.filterPriority ?? "searchAndFilters"
  const location = centerFilter // from Portfolio Grids
    ? "center"
    : filterSortLocation // from Team Grids
    ? filterSortLocation
    : filtersStyles?.filterLocation // from Blog Grids, Image Grids, Logo Grids
    ? filtersStyles?.filterLocation
    : "left"
  const isSplit = location === "split" || location === "splitReverse"
  const { showLoadButton } = flexGrid

  const childrenArr = useMemo(() => React.Children.toArray(children), [
    children,
  ]) // useMemo is needed to prevent unnecessary render on the filter.js component

  const [selectedFilters, setSelectedFilters] = useState({})
  const [currentSort, setCurrentSort] = useState(null)
  const [numLoaded, setNumLoaded] = useState(numToLoad)
  const [localFiltersResetKey, setLocalFiltersResetKey] = useState(0)

  const isSearchFiltersOn = searchProps?.useSearch
  const isAlphaFiltersOn = alphabeticalFilter?.useFilter
  const isTagFilterOn = filters?.length > 0

  const areTagsFilterSelected =
    Object.values(selectedFilters || {}).filter(tag => tag).length > 0
  const isGridInitialViewEmpty = gridInitialView === "empty"

  const [filteredChildrenArr, setFilteredChildrenArr] = useState(() => {
    if (isGridInitialViewEmpty && (isSearchFiltersOn || isAlphaFiltersOn)) {
      return [] // start with no items if search or alpha filter is ON and gridInitialView is set to empty
    }
    return childrenArr // start with all items if grid has no filters at all, or if tag filters are on and others filters are off
  })

  const loadMore = useCallback(
    startWith => {
      const newNum = startWith + numToLoad
      setNumLoaded(newNum)
    },
    [numToLoad]
  ) // useCallback is needed to prevent unnecessary render on the filter.js component

  const getSlicedChildren = () => {
    if (
      !currentSort &&
      (!sort?.defaultSortSelection ||
        sort?.defaultSortSelection === "customOrderBool")
    ) {
      if (showLoadButton) {
        return filteredChildrenArr.slice(0, numLoaded)
      }
      return filteredChildrenArr
    } else {
      if (showLoadButton) {
        return sortFilteredArray().slice(0, numLoaded)
      }
      return sortFilteredArray()
    }
  }

  const updateFilters = (tag, filter) => {
    setSelectedFilters(prevState => {
      const updatedState = {
        ...prevState,
        [filter.id]: tag?.[filter.id],
      }
      if (!tag?.[filter.id]) {
        delete updatedState[filter.id]
      }

      return updatedState
    })
  }

  const filterByTags = (children, selectedFilters) => {
    const selFilters = Object.values(selectedFilters || {}).filter(tag => tag)
    if (selFilters.length === 0) return children

    return children.filter(child =>
      Object.keys(selectedFilters || {}).every(SelFilKey =>
        selectedFilters[SelFilKey]
          ? child.props?.tags
              ?.map(({ name }) => name)
              .includes(selectedFilters[SelFilKey])
          : true
      )
    )
  }

  const doesFieldStartWithLetter = ({ name, lookUpField, letter }) => {
    const cleanedName = name.trim().toLowerCase() //normalizing full name
    const lastName = cleanedName.split(" ").pop()
    const firstName = cleanedName.split(" ")[0]
    const fields = { firstName, lastName }

    return fields[lookUpField].startsWith(letter.toLowerCase())
  }

  const filterByAlphabetical = (
    children,
    alphabeticalFilter,
    selectedAlphaLetterFilter
  ) => {
    if (!isAlphaFiltersOn || selectedAlphaLetterFilter?.length === 0) {
      return children
    }
    // filter grid by team name
    return children.filter(child =>
      doesFieldStartWithLetter({
        name: child.props.name ?? child.props.title, // checking if the letter matches the label of the grid item
        lookUpField: alphabeticalFilter.alphabeticalFilterBy ?? "lastName",
        letter: selectedAlphaLetterFilter,
      })
    )
  }

  const filterBySearchTerm = (children, searchProps, searchedTerm) => {
    if (!searchedTerm?.length) return children

    return children.filter(child => {
      const fields = {
        optionOneBool: searchProps.optionOneBool,
        optionTwoBool: searchProps.optionTwoBool,
        optionThreeBool: searchProps.optionThreeBool,
      }

      // create concatenated string of fields to include
      let stringToSearch = ""
      Object.keys(fields).forEach(field => {
        if (fields[field]) {
          let fieldName = searchProps[`${field}Field`]
          stringToSearch += " " + child.props[`${fieldName}`]
        }
      })

      return stringToSearch.toLowerCase().includes(searchedTerm.toLowerCase())
    })
  }

  const applyFilters = () => {
    let filteredChildren = childrenArr

    if (filterPriority === "searchOnly" && searchedTerm?.length > 0) {
      // apply only the search filter and skip other filters
      filteredChildren = filterBySearchTerm(
        childrenArr,
        searchProps,
        searchedTerm
      )
    } else {
      // apply all filters: tag, alphabetical, and search filters together
      filteredChildren = filterByTags(filteredChildren, selectedFilters)
      filteredChildren = filterByAlphabetical(
        filteredChildren,
        alphabeticalFilter,
        selectedAlphaLetterFilter
      )
      filteredChildren = filterBySearchTerm(
        filteredChildren,
        searchProps,
        searchedTerm
      )
    }

    return filteredChildren
  }

  const throttledUpdateGrid = useCallback(
    debounce(() => {
      const filteredChildren = applyFilters()

      const hasActiveFilters =
        areTagsFilterSelected ||
        searchedTerm?.length > 0 ||
        selectedAlphaLetterFilter?.length > 0

      // case 1: show an empty grid if initial view is empty and either search or alpha filters are on
      if (
        isGridInitialViewEmpty &&
        (isSearchFiltersOn || isAlphaFiltersOn) &&
        !hasActiveFilters
      ) {
        setFilteredChildrenArr([])
      }
      // case 2: apply filters and show relevant items if filters are active
      else if (hasActiveFilters) {
        setFilteredChildrenArr(filteredChildren)
      }
      // case 3: display all grid items if no filters are active
      else {
        setFilteredChildrenArr(childrenArr)
      }

      loadMore(0) // reset the number of loaded items
    }, 200),
    [
      childrenArr,
      filterPriority,
      selectedFilters,
      alphabeticalFilter,
      selectedAlphaLetterFilter,
      searchedTerm,
      searchProps,
      loadMore,
      filteredChildrenArr.length,
    ]
  ) // useDebounce waits for the user search input and filters to change before updating the grid to improve performance and avoid flickering issue

  useEffect(() => {
    throttledUpdateGrid()
  }, [
    children,
    currentColumns,
    selectedFilters,
    searchedTerm,
    selectedAlphaLetterFilter,
    alphabeticalFilter,
    gridInitialView,
    throttledUpdateGrid,
  ])

  const sortFilteredArray = () => {
    const sortSelection = currentSort
      ? currentSort[sort?.id]
      : sort?.defaultSortSelection
      ? sort?.optionsObj[sort.defaultSortSelection]
      : null
    let sortedArr
    switch (sortSelection) {
      case "First Name":
        sortedArr = [...filteredChildrenArr].sort((a, b) =>
          a.props.name.localeCompare(b.props.name)
        )
        return sortedArr
      case "Last Name":
        sortedArr = [...filteredChildrenArr].sort((a, b) => {
          const romanNumerals = [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
          ]
          const aNameArr = a.props.name.split(" ")
          const aLastItem = aNameArr[aNameArr.length - 1]
          const aLastName =
            isNaN(Number(aLastItem)) && !romanNumerals.includes(aLastItem) // make sure last item in array is not a number or roman numeral
              ? aLastItem
              : aNameArr.length - 2 >= 0
              ? aNameArr[aNameArr.length - 2] // if the last item is a number or roman numeral, use the second-to-last item
              : aNameArr[0]
          const bNameArr = b.props.name.split(" ")
          const bLastItem = bNameArr[bNameArr.length - 1]
          const bLastName =
            isNaN(Number(bLastItem)) && !romanNumerals.includes(bLastItem) // make sure last item in array is not a number or roman numeral
              ? bLastItem
              : bNameArr.length - 2 >= 0
              ? bNameArr[bNameArr.length - 2] // if the last item is a number or roman numeral, use the second-to-last item
              : bNameArr[0]
          return aLastName.localeCompare(bLastName)
        })
        return sortedArr
      case "Position Title":
        sortedArr = [...filteredChildrenArr].sort((a, b) =>
          a.props.positionTitle.localeCompare(b.props.positionTitle)
        )
        return sortedArr
      case "Custom Order":
      case sort?.customOrderTitle:
      default:
        return filteredChildrenArr
    }
  }

  const handleJustification = alignmentStr => {
    switch (alignmentStr) {
      case "center":
        return "center"
      case "left":
        return "flex-start"
      case "right":
        return "flex-end"
      case "split":
      case "splitReverse":
        return "space-between"
      default:
        return "flex-start"
    }
  }
  const handleClearFilters = () => {
    setLocalFiltersResetKey(localFiltersResetKey + 1)
    if (clearFiltersBtn?.handleClearFilters)
      clearFiltersBtn.handleClearFilters()
  }

  return (
    <Box width="100%">
      {(isTagFilterOn || sort?.useSort) && (
        <Reveal>
          <Flex
            flexDirection={
              filterSortLocation === "splitReverse" ? "row-reverse" : "row"
            }
            justifyContent={{
              _: handleJustification(isSplit ? "center" : location),
              md: handleJustification(location),
            }}
            flexWrap="wrap"
            mb={6}
          >
            <Flex
              flexDirection="row"
              justifyContent={{
                _: handleJustification(isSplit ? "center" : location),
                md: handleJustification(location),
              }}
              flexWrap="wrap"
            >
              {filters.map(filter => (
                <Filter
                  key={`${filtersResetKey}-${localFiltersResetKey}-${filter.id}`} // unique key to force remount
                  location={location}
                  onChange={tag => {
                    updateFilters(tag, filter)
                  }}
                  gridItems={children}
                  searchedTerm={searchedTerm}
                  filterPriority={filterPriority}
                  {...filter}
                  {...filtersStyles}
                />
              ))}
            </Flex>
            {sort?.useSort && (
              <Sort
                location={location}
                setCurrentSort={setCurrentSort}
                allLabel="Sort By:"
                showFilterLabel={filtersStyles?.showFilterLabel}
                {...sort}
              />
            )}
          </Flex>
        </Reveal>
      )}

      <ClearFiltersButton
        mb={6}
        mt={!isTagFilterOn ? 4 : 0}
        onClick={handleClearFilters}
        {...clearFiltersBtn}
      />

      <FlexGrid {...flexGrid}>
        {getSlicedChildren().map((child, i) => (
          <Box key={`${child.props.forwardKey}-${i}`} height="100%">
            {child}
          </Box>
        ))}
      </FlexGrid>
      {showLoadButton && filteredChildrenArr.length > numLoaded && (
        <Flex mt={8} flexDirection="column" {...loadMoreBtnStyles}>
          <Button
            aria-label="Load more items"
            text="Load More"
            onClick={() => loadMore(numLoaded)}
          />
        </Flex>
      )}
    </Box>
  )
}

FilteredGrid.propTypes = {
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  children: PropTypes.node.isRequired,
  loadMoreBtnStyles: PropTypes.object,
  centerFilter: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filtersStyles: PropTypes.object,
  filterSortLocation: PropTypes.string,
  numToLoad: PropTypes.number,
  currentColumns: PropTypes.number,
  sort: PropTypes.shape(Sort.propTypes),
  searchedTerm: PropTypes.string,
  searchProps: PropTypes.object,
  selectedAlphaLetterFilter: PropTypes.string,
  gridInitialView: PropTypes.oneOf(["allItems", "empty"]),
  filtersResetKey: PropTypes.number,
}

export default FilteredGrid
