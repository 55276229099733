import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"
import Image from "../utils/Image"
import SocialLink from "../utils/SocialLink"
import useBreakpoint from "../../hooks/use-breakpoint"

import Box from "../atoms/Box"
import LayoutChanger from "../atoms/LayoutChanger"
import RichText from "../atoms/RichText"
import { Title } from "../atoms/Typography"
import StandardPadding from "../atoms/StandardPadding"
import Section from "../atoms/Section"

import SectionTitle from "../molecules/SectionTitle"

const NameBox = ({
  title,
  position,
  socialLinks,
  socialLinkStyles,
  showMobileLayout,
  titleVariant = "h2",
  positionVariant = "h3",
  nameAlignment,
  nameColor,
  positionColor,
  ...rest
}) => {
  return (
    <Box {...rest} textAlign={nameAlignment}>
      {title && (
        <Title
          transitionProperty="opacity"
          transitionTimingFunction="ease"
          transitionDuration="md"
          variant={titleVariant === "h1" ? "h1" : "h2"}
          as={titleVariant === "h1" ? "h1" : "h2"}
          color={nameColor}
          textAlign={nameAlignment}
        >
          {title}
        </Title>
      )}
      {position && (
        <Title
          variant={positionVariant === "h2" ? "h2" : "h3"}
          as={positionVariant === "h2" ? "h2" : "h3"}
          mt={4}
          color={positionColor}
          textAlign={nameAlignment}
        >
          {position}
        </Title>
      )}
      {socialLinks && (
        <Box
          className="social"
          mt={showMobileLayout ? 7 : 6}
          textAlign={nameAlignment}
        >
          {socialLinks.map(socialLink => (
            <SocialLink {...socialLink} {...socialLinkStyles} mr={4} />
          ))}
        </Box>
      )}
    </Box>
  )
}
const SubCategoriesBox = ({ subcategories, subcategoryStyles }) => {
  return (
    subcategories &&
    subcategories.length > 0 && (
      <Box display="flex" flexDirection="column" className="subcategories">
        {subcategories.map(subCategory => (
          <SectionTitle
            {...subCategory}
            align={subcategoryStyles?.align}
            titleColor={subcategoryStyles?.titleColor}
            variant={subcategoryStyles?.variant}
            mt={{ _: 7, xl: 0 }}
            maxWidth={42}
          />
        ))}
      </Box>
    )
  )
}
const DescriptionBox = ({
  title,
  description,
  subcategories,
  subcategoryStyles,
  showMobileLayout,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap={
        showMobileLayout
          ? "wrap"
          : {
              lg: "wrap",
              xl: "nowrap",
            }
      }
      {...rest}
    >
      <Box
        display="flex"
        flexDirection={showMobileLayout ? "column" : "row"}
        mr={showMobileLayout ? "0px" : { xl: 8, lg: 0 }}
        width={showMobileLayout ? "auto" : "100%"}
      >
        {description?.content && <RichText {...description} mt={3} />}
      </Box>
      {subcategories && subcategories.length > 0 && (
        <Box
          display="flex"
          flexDirection={showMobileLayout ? "row" : "column"}
          width={showMobileLayout ? "auto" : { xl: "40%", lg: "100%" }}
        >
          <SubCategoriesBox
            subcategories={subcategories}
            subcategoryStyles={subcategoryStyles}
          />
        </Box>
      )}
    </Box>
  )
}

const TextContainer = ({
  title,
  description,
  position,
  subcategories,
  subcategoryStyles,
  socialLinks,
  socialLinkStyles,
  showMobileLayout,
  nameColor,
  positionColor,
  nameAlignment,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      width={showMobileLayout ? "auto" : "100%"}
      flexDirection="column"
      {...rest}
    >
      <NameBox
        title={title}
        position={position}
        nameColor={nameColor}
        nameAlignment={nameAlignment}
        positionColor={positionColor}
        socialLinks={socialLinks}
        socialLinkStyles={socialLinkStyles}
        showMobileLayout={showMobileLayout}
      />
      <DescriptionBox
        title={title}
        description={description}
        subcategories={subcategories}
        subcategoryStyles={subcategoryStyles}
        mt={showMobileLayout ? 7 : 8}
        showMobileLayout={showMobileLayout}
      />
    </Box>
  )
}

const TeamMember = ({
  section,
  subcategories,
  subcategoryStyles,
  image,
  imagePosition = "left",
  teamMemberImagePosition = "left",
  description,
  sectionTitle,
  TMSectionTitle,
  name,
  position,
  socialLinks,
  socialLinkStyles,
  nameColor,
  positionColor,
  nameAlignment,
  mobileImagePosition,
}) => {
  sectionTitle = TMSectionTitle || sectionTitle
  imagePosition = imagePosition ? imagePosition : teamMemberImagePosition

  const customPadding = {
    _:
      section.verticalPadding === "custom"
        ? `${
            section.mobileCustomVerticalPadding ?? section.customVerticalPadding
          }px`
        : 7,
    lg: `${section.customVerticalPadding}px`,
  }
  const title = name
  const mobileVersion = () => (
    <Box display="flex" flexDirection={"column"} maxWidth="100%" width="100%">
      <Reveal height="100%" width="100%">
        <Box
          display="flex"
          flexDirection={
            mobileImagePosition === "top" ? "column-reverse" : "column"
          }
        >
          <NameBox
            title={title}
            position={position}
            positionColor={positionColor}
            nameColor={nameColor}
            nameAlignment={nameAlignment}
            socialLinks={mobileImagePosition === "top" ? socialLinks : null}
            socialLinkStyles={socialLinkStyles}
            mobileImagePosition={mobileImagePosition}
            mb={mobileImagePosition === "center" ? 7 : 0}
            mt={7}
            ml={6}
          />
          <Image
            alternativeText="Image of a Team Member"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "800px",
            }}
            imgStyle={{ objectPosition: "50% 00%", objectFit: "contain" }}
            {...image}
          />
        </Box>
      </Reveal>
      <Reveal height="100%" width="100%">
        <TextContainer
          description={description}
          subcategories={subcategories}
          subcategoryStyles={subcategoryStyles}
          showMobileLayout={true}
          socialLinks={mobileImagePosition === "center" ? socialLinks : null}
          socialLinkStyles={socialLinkStyles}
          nameColor={nameColor}
          positionColor={positionColor}
          nameAlignment={nameAlignment}
          mx={6}
          mb={8}
        />
      </Reveal>
    </Box>
  )

  const desktopVersion = (
    <LayoutChanger
      itemsMoveStackedOnColumn={true}
      child1Style={{
        zIndex: 10,
        paddingLeft: "0px",
        width: "inherit",
        mx: { md: 5, lg: 6, xl: 10 },
      }}
      child2Style={{
        zIndex: 12,
        alignSelf: { _: "center", lg: "stretch" },
        maxWidth: "650px",
      }}
      marginBtwChilds={0}
      mobileMarginBtwChilds={0}
      location={imagePosition === "left" ? "right" : "left"}
      width="100%"
    >
      <Reveal height="100%" width="100%">
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <StandardPadding
            verticalPadding={section.verticalPadding}
            py={section.verticalPadding === "custom" ? customPadding : null}
            position="relative"
            zIndex={10}
            width="100%"
            height="100%"
          >
            <TextContainer
              title={title}
              position={position}
              description={description}
              subcategories={subcategories}
              subcategoryStyles={subcategoryStyles}
              socialLinks={socialLinks}
              socialLinkStyles={socialLinkStyles}
              showMobileLayout={false}
              nameColor={nameColor}
              positionColor={positionColor}
              nameAlignment={nameAlignment}
            />
          </StandardPadding>
        </Box>
      </Reveal>

      {image && (
        <Box
          position="relative"
          width="100%"
          display="flex"
          justifyContent={imagePosition === "left" ? "flex-end" : "flex-start"}
          height="100%"
        >
          <Box position="relative" height="100%" width="100%">
            <Reveal height="100%" width="100%">
              <Image
                alternativeText="Image of a Team Member"
                style={{ width: "100%", height: "100%", maxHeight: "100vh" }}
                {...image}
              />
            </Reveal>
          </Box>
        </Box>
      )}
    </LayoutChanger>
  )
  const renderContent = useBreakpoint("lg")
    ? mobileVersion({ mobileImagePosition })
    : desktopVersion

  return (
    <Section
      {...section}
      maxWidth="100%"
      noStandardPadding={true}
      verticalPadding="custom"
      customVerticalPadding={-1}
    >
      {sectionTitle && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems={sectionTitle.align === "center" ? "center" : "flex-start"}
        >
          <Reveal>
            <SectionTitle {...sectionTitle} ml={6} mb={9} maxWidth={42} />
          </Reveal>
        </Box>
      )}

      {renderContent}
    </Section>
  )
}

export default TeamMember

TeamMember.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  name: PropTypes.string,
  position: PropTypes.string,
  nameColor: PropTypes.oneOf(["text", "primary", "secondary"]),
  imagePosition: PropTypes.oneOf(["left", "right"]),
  positionColor: PropTypes.oneOf(["text", "primary", "secondary"]),
  nameAlignment: PropTypes.oneOf(["left", "center"]),
  description: PropTypes.object,
  image: PropTypes.object,
  subcategories: PropTypes.shape(SectionTitle.strapiProps),
  socialLinks: PropTypes.arrayOf(PropTypes.shape(SocialLink.strapiProps)),
  socialLinkStyles: PropTypes.oneOf(["none", "outline", "solid"]),
}

TeamMember.propTypes = {
  ...TeamMember.strapiProps,
}

export const query = graphql`
  fragment TeamMember on STRAPI__COMPONENT_SECTIONS_TEAM_MEMBER {
    strapi_id
    name
    TEAMMEMBERSection: section {
      ...Section
    }
    TMSectionTitle: sectionTitle {
      ...SectionTitle
    }
    subcategoryStyles {
      ...Subcategory
    }
    subcategories {
      ...Subcategory
    }
    description {
      ...RichText
    }
    image {
      ...ImageWithConstrainedLayout
    }
    socialLinks {
      ...SocialLink
    }
    socialLinkStyles {
      ...SocialLinkStyles
    }
    position
    nameColor
    positionColor
    nameAlignment
    teamMemberImagePosition: imagePosition
    mobileImagePosition
  }
`
